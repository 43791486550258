<template>
  <el-dialog
    top="30px"
    append-to-body
    :visible.sync="currentShowFlag"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('thirdParty_reportExport.rebatesReplace') }}
    </div>
    <div>
      <simple-form
        ref="form"
        v-model="formModel"
        label-width="80px"
        :form-field="formField"
        :grid="{xs: 24, sm: 12, md: 8}"
        :view-flag="viewFlag"
      />
    </div>
    <div slot="footer">
      <el-button @click="handleDialogClose">
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
      <el-button
        v-if="!viewFlag"
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('thirdParty_reportExport.generate') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
const BASEURL = {
  generate: '/jlr/tt/tpSourceData/replaceRebate',
  sourceData: '/jlr/tt/selfExaminationData',
  vme: '/jlr/tt/vme/list',
  vrd: '/jlr/tt/vrd/list'
}
export default {
  name: 'ReportExportDetail',
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    detailData: { type: Object }
  },
  data () {
    return {
      formModel: { isCost: 'N' },
      dataSource: [],
      vmeList: [],
      vrdList: [],
      statusList: [],
      vmeLoadingFlag: false,
      vrdLoadingFlag: false
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    },
    formField () {
      return [
        {
          prop: 'version',
          type: 'Input',
          component: { clearable: true },
          label: this.$t('thirdParty_reportExport.versionName'),
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'period',
          type: 'Datepicker',
          label: this.$t('thirdParty_reportExport.month'),
          component: { clearable: true, type: 'month', valueFormat: 'yyyyMM' },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'bizStatus',
          type: 'Select',
          label: this.$t('thirdParty_reportExport.status'),
          component: {
            optionList: this.statusList,
            valueKey: 'id',
            clearable: true
          },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'isCost',
          label: this.$t('thirdParty_reportExport.costData'),
          type: 'Select',
          component: {
            optionList: this.$getDictList('yes_no'),
            clearable: true
          }
        },
        {
          prop: 'vmeId',
          label: 'VME',
          type: 'SearchableInput',
          component: {
            remoteMethod: this.getVmeList,
            loading: this.vmeLoadingFlag,
            optionList: this.vmeList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getVmeList()
            }
          }
        },
        {
          prop: 'vrdId',
          label: 'VRD',
          type: 'SearchableInput',
          component: {
            remoteMethod: this.getVrdList,
            loading: this.vrdLoadingFlag,
            optionList: this.vrdList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getVrdList()
            }
          }
        }
      ]
    }
  },
  mounted () {
    // bizStatus 字典项去掉最后一条
    const statusList = this.$getDictList('tt_self_examination_data_head_biz_status')
    this.statusList = this.$_.pullAll(statusList, [statusList.find(ele => ele.value === 'V4')])
  },
  methods: {
    getVmeList (version = '') {
      this.vmeLoadingFlag = true
      this.$axios
        .post(BASEURL.vme, { pageSize: -1, filter: [{ left: 'version', operate: 'like', right: version }] })
        .then(resp => {
          const respData = resp.data
          this.vmeList = respData.list.map(item => {
            return { key: item.id, value: item.id, label: item.version }
          })
        })
        .finally(() => {
          this.vmeLoadingFlag = false
        })
    },
    getVrdList (version = '') {
      this.vrdLoadingFlag = true
      this.$axios
        .post(BASEURL.vrd, { pageSize: -1, filter: [{ left: 'version', operate: 'like', right: version }] })
        .then(resp => {
          const respData = resp.data
          this.vrdList = respData.list.map(item => {
            return { key: item.id, value: item.id, label: item.version }
          })
        })
        .finally(() => {
          this.vrdLoadingFlag = false
        })
    },
    handleDialogClose () {
      this.currentShowFlag = false
    },
    handleDataSubmit () {
      this.$refs.form.$refs.simpleForm.validate(status => {
        if (status) {
          if (this.formModel.isCost === 'N' && !this.formModel.vrdId && !this.formModel.vmeId) {
            return this.$message({
              type: 'warning',
              message: this.$t('thirdParty_reportExport.validateTip')
            })
          }
          const loadingFlag = this.$loading({
            target: this.$el.querySelector('.el-dialog')
          })
          const submitModel = this.$_.cloneDeep(this.formModel)
          this.$axios
            .post(BASEURL.generate, submitModel)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({
                type: 'success',
                message: this.$t('tip.saveSuccess')
              })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },
    handleDialogOpen () {},
    handleDialogClosed () {
      this.$refs.form.resetFields()
      this.formModel = {}
    }
  }
}
</script>
